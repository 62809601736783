import React from "react"
import ReactMarkdown from "react-markdown"
import Category from "../category"

export default function TitledParagraph({ title, content, color }) {
  return (
    <div>
      <Category color={color}>{title}</Category>
      <ReactMarkdown>{content}</ReactMarkdown>
    </div>
  )
}
