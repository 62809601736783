// extracted by mini-css-extract-plugin
export var content = "linearblock-module--content--3A25f";
export var container = "linearblock-module--container--2BbIq";
export var monoBlock = "linearblock-module--monoBlock--17nBO";
export var panelsContainer = "linearblock-module--panelsContainer--10mrj";
export var panelComponent = "linearblock-module--panelComponent--1nc_h";
export var orange = "linearblock-module--orange--RixHj";
export var orange1 = "linearblock-module--orange1--2wMmO";
export var orange2 = "linearblock-module--orange2--3yoWx";
export var green = "linearblock-module--green--3GXlc";
export var lightgray = "linearblock-module--lightgray--1rwVE";
export var warmgray = "linearblock-module--warmgray--xrsYw";
export var area = "linearblock-module--area--3KDDs";
export var leftContent = "linearblock-module--leftContent--17Z7D";
export var rightContent = "linearblock-module--rightContent--EO_cg";
export var imghead = "linearblock-module--imghead--37fSS";