import React from "react"
import {
  leftContent,
  container,
  area,
  rightContent,
  imghead,
  content,
  monoBlock,
  panelComponent,
  panelsContainer,
  orange2,
  orange1,
  orange,
  green,
  lightgray,
  warmgray,
} from "./linearblock.module.scss"

export default function LinearBlock({
  color,
  img,
  leftComponent,
  rightComponent,
  oneComponent = undefined,
  listComponent = undefined,
}) {
  let leftContentDom = (
    <div className={leftContent + " " + area}>{leftComponent}</div>
  )
  let rightContentDom = (
    <div className={rightContent + " " + area}>{rightComponent}</div>
  )
  let styleColor
  switch (color) {
    case "orange":
      styleColor = orange
      break
    case "orange1":
      styleColor = orange1
      break
    case "orange2":
      styleColor = orange2
      break
    case "green":
      styleColor = green
      break
    case "lightgray":
      styleColor = lightgray
      break
    case "warmgray":
      styleColor = warmgray
      break
    default:
      break
  }

  if (leftComponent !== undefined && leftComponent.type === String) {
    leftContentDom = (
      <div className={leftContent + " " + area}>{leftComponent}</div>
    )
  }
  if (rightComponent !== undefined && rightComponent.type === String) {
    rightContentDom = (
      <div className={rightContent + " " + area}>{rightComponent}</div>
    )
  }

  if (img !== undefined && leftComponent === undefined) {
    leftContentDom = (
      <div className={leftContent + " " + area + " " + imghead}>{img}</div>
    )
  }
  if (img !== undefined && rightComponent === undefined) {
    rightContentDom = (
      <div className={rightContent + " " + area + " " + imghead}>{img}</div>
    )
  }

  let result
  if (oneComponent !== undefined) {
    oneComponent = <div>{oneComponent}</div>

    result = (
      <div className={content + " " + styleColor}>
        <div className={monoBlock}>{oneComponent}</div>
      </div>
    )
  } else if (listComponent !== undefined) {
    let components = listComponent.map((component, key) => (
      <div key={key} className={panelComponent}>
        {component}
      </div>
    ))
    result = (
      <div className={content + " " + styleColor}>
        <div className={panelsContainer}>{components}</div>
      </div>
    )
  } else {
    result = (
      <div className={content + " " + styleColor}>
        <div className={container}>
          {leftContentDom}
          {rightContentDom}
        </div>
      </div>
    )
  }
  return result
}
