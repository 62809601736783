import React from "react"
import { injectIntl } from "gatsby-plugin-intl"
import Layout from "../layout/layout"
import LinearBlock from "../layout/linearblock/linearblock"
import { ServicesPage } from "../components/enum/pages"
import {
  imageTop,
  imageContainer,
  layoutContainer,
  containerNewsList,
  textOnImage,
  subtitle,
  overlayLogo,
  overlay,
} from "../styles/home.module.scss"
import logoTransparent from "../assets/images/logo-blanc.svg"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import SEO from "../seo"
import TitledParagraph from "../components/titledParagraph/titledParagraph"
import { LatestNews } from "./newsList"

export const pageQuery = graphql`
  query {
    allStrapiServices(limit: 10) {
      nodes {
        Banner
        BannerTitle
        PaperText
        PaperPartTitle
        FormatTitle
        FormatText
        TransformText
        TransformTitle
        published_at
        updated_at
        BannerImg {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
            }
          }
        }
        FormatImages {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
            }
          }
        }
        PaperPartImages {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
            }
          }
        }
        TransformImages {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
            }
          }
        }
      }
    }
    allStrapiArticle(
      limit: 4
      sort: { fields: publishedAt, order: DESC }
      filter: { status: { eq: "published" } }
    ) {
      edges {
        node {
          strapiId
          slug
          title
          description
          publishedAt
          category {
            name
          }
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
              }
            }
          }
        }
      }
    }
  }
`

function Services(props) {
  const { intl, data } = props
  const metaTitle = intl.formatMessage({ id: "menu.home" })
  const metaDescription = intl.formatMessage({ id: "home.description" })

  let servicesData = data.allStrapiServices.nodes[0]
  let transformPartPretty = partPrettify(
    servicesData.TransformText,
    servicesData.TransformImages
  )
  let paperPartPretty = partPrettify(
    servicesData.PaperText,
    servicesData.PaperPartImages
  )
  let formatPartPretty = partPrettify(
    servicesData.FormatText,
    servicesData.FormatImages
  )

  return (
    <Layout active={ServicesPage}>
      <SEO
        seo={{
          metaTitle: metaTitle,
          metaDescription: metaDescription,
          locale: intl.locale,
        }}
      />

      <div className={layoutContainer}>
        <div className={imageContainer}>
          <GatsbyImage
            alt=""
            image={
              servicesData.BannerImg.localFile.childImageSharp.gatsbyImageData
            }
            className={imageTop}
            objectFit="cover"
            objectPosition="50% 50%"
          />
          <div className={overlay} />
          <img className={overlayLogo} src={logoTransparent} alt="Logo" />
          <div className={textOnImage}>
            <div className={subtitle}>{servicesData.Banner}</div>
          </div>
        </div>

        <LinearBlock
          color="orange"
          oneComponent={<h1> {servicesData.PaperPartTitle} </h1>}
        />
        {paperPartPretty}
        <LinearBlock
          color="orange"
          oneComponent={<h1> {servicesData.FormatTitle} </h1>}
        />
        {formatPartPretty}
        <LinearBlock
          color="orange"
          oneComponent={<h1> {servicesData.TransformTitle} </h1>}
        />
        {transformPartPretty}
        <LinearBlock color="lightgray" oneComponent={<h1> Actualités </h1>} />

        <LinearBlock
          oneComponent={
            <div className={containerNewsList}>
              <LatestNews
                articleEdges={data.allStrapiArticle.edges}
                intl={intl}
              />
            </div>
          }
        />
      </div>
    </Layout>
  )
}
function partPrettify(content, images) {
  const part = content
    .split("\n## ")
    .map(s => s.replace("## ", "").split("\n"))
    .filter(item => item !== [""])
  const partPretty = part.map((paragraph, key) => {
    const titre = paragraph.shift()
    const newcontent = paragraph.join("\n")
    if (key % 2 === 0) {
      return (
        <LinearBlock
          key={key}
          img={
            <GatsbyImage
              alt=""
              image={images[key].localFile.childImageSharp.gatsbyImageData}
            />
          }
          leftComponent={
            <TitledParagraph color="green" title={titre} content={newcontent} />
          }
        />
      )
    } else {
      return (
        <LinearBlock
          key={key}
          img={
            <GatsbyImage
              alt=""
              image={images[key].localFile.childImageSharp.gatsbyImageData}
            />
          }
          rightComponent={
            <TitledParagraph color="green" title={titre} content={newcontent} />
          }
        />
      )
    }
  })
  return partPretty
}

export default injectIntl(Services)
