// extracted by mini-css-extract-plugin
export var imageTop = "home-module--imageTop--3ItSI";
export var layoutContainer = "home-module--layoutContainer--36sDC";
export var homeImageContainer = "home-module--homeImageContainer--3BIrK";
export var imageContainer = "home-module--imageContainer--3lP-V";
export var overlay = "home-module--overlay--2INz9";
export var overlayGreen = "home-module--overlayGreen--1pgO7";
export var overlayLogo = "home-module--overlayLogo--ULVTJ";
export var textOnImage = "home-module--textOnImage--3RJDQ";
export var title = "home-module--title--2fwWS";
export var subtitle = "home-module--subtitle---e1Cp";
export var text = "home-module--text--12BxN";
export var button = "home-module--button--1auY6";
export var ourNewsButton = "home-module--ourNewsButton--3IUdM";
export var latestNewsContainer = "home-module--latestNewsContainer--PVFGO";
export var imgEmphasis = "home-module--imgEmphasis--3Wi2r";
export var newsEmphasis = "home-module--newsEmphasis--2aI16";
export var newsTitle = "home-module--newsTitle--1Q4bL";
export var containerNewsList = "home-module--containerNewsList--1od9o";
export var news = "home-module--news--1qm2_";