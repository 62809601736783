import React from "react"
import Layout from "../layout/layout"
import { NewsPage } from "../components/enum/pages"
import SEO from "../seo"
import { graphql } from "gatsby"
import { injectIntl, navigate } from "gatsby-plugin-intl"
import { GatsbyImage } from "gatsby-plugin-image"
import Category from "../components/category"
import logoTransparent from "../assets/images/logo-blanc.svg"

import {
  newsContainer,
  newsImage,
  newsTitle,
  newsContent,
  newsPublicationDate,
  container,
} from "../styles/newsList.module.scss"
import {
  layoutContainer,
  imageContainer,
  imageTop,
  overlay,
  overlayLogo,
  textOnImage,
  subtitle,
} from "../styles/home.module.scss"

export const pageQuery = graphql`
  query MyQuery {
    allStrapiAccueil(limit: 10) {
      nodes {
        Banner
        published_at
        updated_at
        BannerImg {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
            }
          }
        }
      }
    }
    allStrapiArticle(
      sort: { fields: publishedAt, order: DESC }
      filter: { status: { eq: "published" } }
    ) {
      edges {
        node {
          strapiId
          description
          category {
            name
          }
          publishedAt
          slug
          title
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
              }
            }
          }
        }
      }
    }
  }
`
function News({ news, intl }) {
  let formatDate = null
  if (intl == null) formatDate = news.publishedAt
  else formatDate = intl.formatDate(news.publishedAt)
  return (
    <div
      className={newsContainer}
      onClick={() => {
        navigate("/blog/" + news.slug)
      }}
    >
      <GatsbyImage
        alt=""
        image={news.image.localFile.childImageSharp.gatsbyImageData}
        objectFit="cover"
        objectPosition="50% 50%"
        className={newsImage}
      />
      <Category>{news.category.name}</Category>
      <div className={newsTitle}>{news.title}</div>
      <div className={newsContent}>{news.description}</div>
      <div className={newsPublicationDate}>{formatDate}</div>
    </div>
  )
}

function renderNewsList(data, intl) {
  return data.allStrapiArticle.edges.map(e => {
    let news = e.node
    return <News key={news.strapiId} news={news} intl={intl} />
  })
}

export function LatestNews({ articleEdges, intl }) {
  return articleEdges.map(e => {
    let news = e.node
    return <News key={news.strapiId} news={news} intl={intl} />
  })
}

function NewsList(props) {
  const { intl, data } = props

  const metaTitle = intl.formatMessage({ id: "menu.news" })
  const metaDescription = intl.formatMessage({ id: "news.description" })

  const newsData = data.allStrapiAccueil.nodes[0]
  return (
    <Layout active={NewsPage}>
      <SEO
        seo={{
          metaTitle: metaTitle,
          metaDescription: metaDescription,
          locale: intl.locale,
        }}
      />

      <div className={layoutContainer}>
        <div className={imageContainer}>
          <GatsbyImage
            alt=""
            image={newsData.BannerImg.localFile.childImageSharp.gatsbyImageData}
            className={imageTop}
            objectFit="cover"
            objectPosition="50% 50%"
          />
          <div className={overlay} />
          <img className={overlayLogo} src={logoTransparent} alt="Logo" />
          <div className={textOnImage}>
            <div className={subtitle}>{newsData.Banner}</div>
          </div>
        </div>

        <div className={container}>{renderNewsList(data, intl)}</div>
      </div>
    </Layout>
  )
}
export default injectIntl(NewsList)
